
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-secondary py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <div className="mb-8 md:mb-0">
            <div className="flex items-center mb-4">
              <img
                src="/lovable-uploads/f8bfc9b3-dcfb-4041-98d4-6d51ffcf45de.png"
                alt="PhysioAdelaide Logo"
                className="h-12 w-auto mr-3"
              />
              <span className="text-2xl font-bold text-primary">PhysioAdelaide</span>
            </div>
            <p className="text-muted-foreground max-w-xs">
              Professional physiotherapy services in Adelaide, helping you recover faster and live better.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Services</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/sports-injury" className="text-muted-foreground hover:text-accent">
                    Sports Injury Rehabilitation
                  </Link>
                </li>
                <li>
                  <Link to="/chronic-pain" className="text-muted-foreground hover:text-accent">
                    Chronic Pain Management
                  </Link>
                </li>
                <li>
                  <Link to="/post-surgery" className="text-muted-foreground hover:text-accent">
                    Post-Surgery Rehabilitation
                  </Link>
                </li>
                <li>
                  <Link to="/geriatric-care" className="text-muted-foreground hover:text-accent">
                    Geriatric Care
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Treatments</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/manual-therapy" className="text-muted-foreground hover:text-accent">
                    Manual Therapy
                  </Link>
                </li>
                <li>
                  <Link to="/gentle-treatment" className="text-muted-foreground hover:text-accent">
                    Gentle Treatment
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <ul className="space-y-2">
                <li className="text-muted-foreground">123 Main Street</li>
                <li className="text-muted-foreground">Adelaide, SA 5000</li>
                <li className="text-muted-foreground">Phone: (08) 8123 4567</li>
                <li>
                  <a
                    href="mailto:info@physioadelaide.com"
                    className="text-accent hover:underline"
                  >
                    info@physioadelaide.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t border-border pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-muted-foreground mb-4 md:mb-0">
              © {new Date().getFullYear()} PhysioAdelaide. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <a href="#" className="text-muted-foreground hover:text-accent">
                Privacy Policy
              </a>
              <a href="#" className="text-muted-foreground hover:text-accent">
                Terms of Service
              </a>
              <a href="#" className="text-muted-foreground hover:text-accent">
                Sitemap
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
